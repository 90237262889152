/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.page {
  margin: 40px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
}
.header .goback {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
}
.header .action {
  margin-top: 0;
}
@media print {
  .header {
    display: none;
  }
}
