/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.main {
  display: flex;
  align-items: center;
  width: 400px;
  margin: 0 auto;
}
.main .form {
  flex: 1;
  margin-left: -6px;
  padding: 20px 40px;
  height: 480px;
  background-color: #fff;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.main .top {
  text-align: center;
  margin-bottom: 50px;
}
.main .top .logo {
  height: 70px;
}
.main .tabs {
  padding: 0 2px;
  margin: 0 -2px;
}
.main .tabs :global .ant-tabs-tab {
  font-size: 16px;
  line-height: 24px;
}
.main .tabs :global .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 34px;
}
.main :global .ant-tabs .ant-tabs-bar {
  border-bottom: 0;
  margin-bottom: 24px;
  text-align: center;
}
.main :global .ant-form-item {
  margin-bottom: 24px;
}
.main .prefixIcon {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
}
.main .getCaptcha {
  display: block;
  width: 100%;
}
.main .additional {
  text-align: left;
}
.main .additional .forgot {
  float: right;
}
.main .additional .submit {
  width: 100%;
  margin-top: 24px;
}
.main .additional :global .ant-form-item-control {
  line-height: 22px;
}
.main .other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;
}
.main .other .register {
  float: right;
}
